import React, { useEffect, useState } from 'react'
import moment from 'moment';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import MetaTags from "react-meta-tags"
import { Container, Col, Row, Modal, Input } from "reactstrap"
import { apiDelete, apiGet, apiPost } from 'helpers/api_helper';

const CalendarIndex = props => {

  const [mdlIsOpen, setMdlIsOpen] = useState(false);
  const [previewMdlIsOpen, setPreviewMdlIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [previewedEvent, setPreviewedEvent] = useState({ name: '', email: '', phone: '' });
  const [selectedUser, setSelectedUser] = useState({});
  const [users, setUsers] = useState([]);
  const [type, setType] = useState("off");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");


  const [events, setEvents] = useState([]);

  const initEvents = () => {
    apiGet('calendar/for-admin')
      .then(({ data }) => {
        setEvents(data);
      }).catch(err => { console.log(err) })
  }

  const getUsers = async () => {
    const { data } = await apiGet("user/admin-get-users", true)
    setSelectedUser(data[0])
    if (data[0]) {
      setEmail(data[0].email)
      setPhone(data[0].phone)
    }
    setUsers(data)
  }

  const handleSave = () => {
    apiPost('calendar/save-by-admin', {
      detail: null,
      start: selectedEvent.startStr,
      end: selectedEvent.endStr,
      type,
      user_id: type === "off" ? null : selectedUser._id,
      detail: type === "off" ? null : { email, phone, name: selectedUser.name }
    }, true).then(() => {
      setMdlIsOpen(false);
      setType("off");
      setPhone(selectedUser.phone);
      setEmail(selectedUser.email);
      setSelectedEvent({});
      initEvents();
    })
  }

  const handleDelete = () => {
    apiDelete(`calendar/${previewedEvent.id}`, true).then(() => {
      setPreviewMdlIsOpen(false);
      initEvents();
    })
  }

  const handleEventClick = (event) => {
    const calendarEvent = events.find(e => e.id === event.id);
    setPreviewedEvent(calendarEvent)
    setPreviewMdlIsOpen(true)
  }

  useEffect(() => {
    initEvents();
    getUsers();
  }, [0])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Esnek Beyin</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col md={12}>
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'timeGridWeek'
                }}
                initialView='timeGridWeek'
                height={"auto"}
                editable={false}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                showNonCurrentDates={false}
                weekends={true}
                locale={'tr'}
                slotDuration={'01:00:00'}
                slotMinTime={'07:00'}
                slotMaxTime={'20:00'}
                events={events} // alternatively, use the `events` setting to fetch from a feed
                select={(event) => {
                  setSelectedEvent(event)
                  setType("off")
                  setMdlIsOpen(true)
                }}
                eventClick={(args) => { handleEventClick(args.event) }}
                allDaySlot={false}
                eventsSet={() => { }}
                eventTimeFormat={{
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                }}
                slotLabelFormat={{
                  hour: 'numeric',
                  minute: '2-digit',
                  hour12: false,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        size="lg"
        isOpen={mdlIsOpen}
        toggle={() => setMdlIsOpen(!mdlIsOpen)}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">Yeni Randevu</h5>

          <button
            onClick={() => setMdlIsOpen(false)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Row>
              <Col md={6}>
                <p>
                  <strong>Başlangıç Tarihi:</strong></p>
              </Col>
              <Col md={6}>
                <p>
                  {moment(selectedEvent.start).format("DD/MM/YY - HH:mm")}
                </p>
              </Col>
              <Col md={6}>
                <p>
                  <strong>Bitiş Tarihi:</strong></p>
              </Col>
              <Col md={6}>
                <p>
                  {moment(selectedEvent.end).format("DD/MM/YY - HH:mm")}
                </p>
              </Col>
              <Col md={6}>
                <p>
                  <strong>Randevu Türü:</strong></p>
              </Col>
              <Col md={6}>
                <p>
                  <select
                    className="form-select"
                    onChange={e => { setType(e.target.value) }}
                  >
                    <option value="off" defaultValue>Erişime Kapat</option>
                    {/* <option value="event">Görüşme</option> */}
                  </select>
                </p>
              </Col>
            </Row>
          </Row>
          {type === "event" && (<>
            <hr></hr>
            <Row>
              <Col md={6}>
                <p>
                  <strong>Üye Listesi:</strong></p>
              </Col>
              <Col md={6}>
                <p>
                  <select
                    className="form-select"
                    onChange={e => { setSelectedUser(users.find((user) => user._id === e.target.value)) }}
                  >
                    {users.map((user, index) => {
                      return <option key={index} defaultValue={index === 0} value={user._id}>{`${user.name} - ${user.email}`}</option>
                    })}
                  </select>
                </p>
              </Col>
              <Col md={6}>
                <p>
                  <strong>Üye Telefon:</strong></p>
              </Col>
              <Col md={6}>
                <p>
                  <Input
                    name="phone"
                    className="form-control"
                    placeholder="Üye Telefonu Numarası"
                    type="text"
                    required
                    value={phone}
                    defaultValue={selectedUser.phone}
                    onChange={e => setPhone(e.target.value)}
                  />
                </p>
              </Col>
              <Col md={6}>
                <p>
                  <strong>Üye E-Posta:</strong></p>
              </Col>
              <Col md={6}>
                <p>
                  <p>
                    <Input
                      name="phone"
                      className="form-control"
                      placeholder="Üye E-Posta Adresi"
                      type="text"
                      required
                      defaultValue={selectedUser.email}
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </p>
                </p>
              </Col>
            </Row>
          </>)}
          <hr></hr>
          <Row className='mt-5'>
            <Col lg={3}></Col>
            <Col lg={3}>
              <button
                className="btn btn-md btn-danger w-100"
                onClick={() => setMdlIsOpen(false)}
              >
                Geri
              </button>
            </Col>
            <Col lg={3}>
              <button
                className="btn btn-md btn-success w-100"
                onClick={handleSave}
              >
                Kaydet
              </button>
            </Col>
          </Row>
        </div>
      </Modal>


      <Modal
        size="md"
        isOpen={previewMdlIsOpen}
        toggle={() => setPreviewMdlIsOpen(!previewMdlIsOpen)}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">Randevu Detayı</h5>

          <button
            onClick={() => setPreviewMdlIsOpen(false)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Row>
              <Col md={6}>
                <p>
                  <strong>Başlangıç Tarihi:</strong></p>
              </Col>
              <Col md={6}>
                <p>
                  {moment(previewedEvent.start).format("DD/MM/YY - HH:mm")}
                </p>
              </Col>
              <Col md={6}>
                <p>
                  <strong>Bitiş Tarihi:</strong></p>
              </Col>
              <Col md={6}>
                <p>
                  {moment(previewedEvent.end).format("DD/MM/YY - HH:mm")}
                </p>
              </Col>
              <Col md={6}>
                <p>
                  <strong>Randevu Türü:</strong></p>
              </Col>
              <Col md={6}>
                <p>
                  {previewedEvent.type === 'off' ? 'Erişime Kapalı' : 'Görüşme'}
                </p>
              </Col>
            </Row>
          </Row>
          {previewedEvent.type === "event" && (<>
            <hr></hr>
            <Row>
              <Col md={6}>
                <p>
                  <strong>Üye Adı:</strong></p>
              </Col>
              <Col md={6}>
                <p>
                  {previewedEvent.detail.name}
                </p>
              </Col>
              <Col md={6}>
                <p>
                  <strong>Üye Telefon:</strong></p>
              </Col>
              <Col md={6}>
                <p>
                  {previewedEvent.detail.phone}
                </p>
              </Col>
              <Col md={6}>
                <p>
                  <strong>Üye E-Posta:</strong></p>
              </Col>
              <Col md={6}>
                <p>
                  <p>
                    {previewedEvent.detail.email}
                  </p>
                </p>
              </Col>
            </Row>
          </>)}
          <hr></hr>
          <Row>
            <Col lg={3}></Col>
            <Col lg={3}>
              <button
                className="btn btn-md btn-danger w-100"
                onClick={() => setPreviewMdlIsOpen(false)}
              >
                Geri
              </button>
            </Col>
            <Col lg={3}>
              <button
                className="btn btn-md btn-warning w-100"
                onClick={handleDelete}
              >
                Sil
              </button>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default CalendarIndex