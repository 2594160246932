import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Badge, Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import { apiGet, apiPost, apiDelete } from "helpers/api_helper"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { useParams } from "react-router-dom"

const FileIndex = () => {
  const params = useParams()
  const [tableData, setTableData] = useState([])

  const getFiles = () => {
    if (params.packageId) {
      return apiGet(`package/files/${params.packageId}`).then(({ data }) => {
        setTableData(data)
      })
    }
    return apiGet("file/")
      .then(({ data }) => {
        setTableData(data)
      })
      .catch(error => console.log(err))
  }

  const deleteFile = id => {
    apiDelete(`file/${id}`).then(getFiles)
  }

  const showFile = id => {
    apiGet(`file/content-url/${id}`).then(({ data }) =>
      window.open(data, "_blank")
    )
  }

  useEffect(() => getFiles(), [0])

  const statusRender = (cell, row, rowIndex, formatExtraData) => {
    if (row.isPaid) {
      return <>Ücretli</>
    }
    return <>Ücretsiz</>
  }

  const actionsBtnGroup = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Row>
          <Col md={12}>
            <Button
              className="btn btn-sm btn-success w-100"
              onClick={() => {
                showFile(row._id)
              }}
            >
              Göster
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Button
              className="btn btn-sm btn-danger w-100"
              onClick={() => {
                deleteFile(row._id)
              }}
            >
              Sil
            </Button>
          </Col>
        </Row>
      </>
    )
  }
  const columns = [
    {
      dataField: "_id",
      text: "Id",
      sort: false,
    },
    {
      dataField: "name",
      text: "Dosya Adı",
      sort: true,
    },
    {
      dataField: "fileType",
      text: "Dosya Uzantısı",
      sort: true,
    },
    {
      dataField: "branch",
      text: "Sınıf",
      sort: true,
    },
    {
      dataField: "status",
      text: "Tür",
      sort: true,
      formatter: statusRender,
    },
    {
      dataField: "actions",
      text: "İşlemler",
      sort: true,
      formatter: actionsBtnGroup,
    },
  ]
  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]
  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length,
    custom: true,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Esnek Beyin / Dosyalar</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Dosyalar" breadcrumbItem="Tümü" />

          <Row>
            <Col className="col-12">
              <a href="/files/upload" className="btn btn-success w-sm mb-3">
                Yeni Dosya Yükle
              </a>
              <Card>
                <CardHeader hidden={!params.packageId}>
                  {params.packageId} içerik listesi
                </CardHeader>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={tableData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={tableData}
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FileIndex
