import React, { Component, useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody, Label, Input } from "reactstrap"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { apiGet, apiPost } from "../../helpers/api_helper"
import SweetAlert from "react-bootstrap-sweetalert"

const BlogEditor = () => {
  const history = useHistory()
  const location = useLocation()
  const [contentState, setContentState] = useState(EditorState.createEmpty())
  const [header, setHeader] = useState("")
  const [summary, setSummary] = useState("")
  const [status, setStatus] = useState("")
  const [sweetAlert, setSweetAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState()

  useEffect(async () => {
    let html = ' "<p>Hey this <strong>editor</strong> rocks 😀</p>"'
    if (location.state && location.state.id) {
      const { data: blogPost } = await apiGet(
        `blog/get-post/${location.state.id}`
      )
      html = blogPost.text

      setHeader(blogPost.header)
      setSummary(blogPost.summary)
      setStatus(blogPost.status)
    }
    updateEditor(html)
  }, [0])

  const onSuccess = () => {
    setSweetAlert(false)
    history.push("/blog")
  }
  const updateEditor = html => {
    const contentBlock = htmlToDraft(html)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      const editorState = EditorState.createWithContent(contentState)
      setContentState(editorState)
    }
  }

  const savePost = () => {
    const data = {
      header,
      summary,
      text: draftToHtml(convertToRaw(contentState.getCurrentContent())),
    }

    if (location.state && location.state.id) {
      data._id = location.state.id
      data.status = status
    }
    apiPost("blog/save-post", data, true)
      .then(() => setSweetAlert(true))
      .catch(e => setErrorAlert(e.response.data))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Esnek Beyin / Blog</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Blog Yazısı" />

          <Row className="mb-3">
            <Col xs={12} md={4}>
              <Label>Başlık</Label>
              <Input
                name="header"
                value={header}
                className="form-control"
                placeholder="Bir başlık giriniz"
                type="text"
                required
                onChange={e => setHeader(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={4}>
              <Label>Özet</Label>
              <Input
                type="textarea"
                id="textarea"
                onChange={e => setSummary(e.target.value)}
                maxLength="225"
                rows="3"
                placeholder="Bir özet yazınız"
                value={summary}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Label>Yazı</Label>
                  <Editor
                    editorState={contentState}
                    wrapperStyle={{ height: "850px" }}
                    editorStyle={{ height: "700px" }}
                    onEditorStateChange={editorState => {
                      setContentState(editorState)
                    }}
                  >
                    <textarea
                      disabled
                      value={draftToHtml(
                        convertToRaw(contentState.getCurrentContent())
                      )}
                    />
                  </Editor>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={2}>
              <Input
                type="button"
                value="Kaydet"
                className="btn btn-success mb-3"
                onClick={savePost}
              />
            </Col>
          </Row>
        </div>
      </div>
      {sweetAlert ? (
        <SweetAlert
          title="Yazı kaydedildi!"
          timeout={2000}
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
          }}
          showCloseButton={false}
          showConfirm={false}
          success
          onConfirm={onSuccess}
        ></SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert
          title={errorAlert.message}
          timeout={2000}
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
          }}
          showCloseButton={false}
          showConfirm={false}
          error
          onConfirm={() => {
            setErrorAlert(undefined)
          }}
        ></SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

export default BlogEditor
