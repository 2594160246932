import React, { useState, useRef, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Col,
  Container,
  Row,
  Table,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Modal,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { apiGet, apiPost } from "../../helpers/api_helper"
import SweetAlert from "react-bootstrap-sweetalert"
import { useHistory } from "react-router-dom"

const FileUpload = () => {
  const history = useHistory()
  const [isPaid, setIsPaid] = useState(true)
  const [isVideo, setIsVideo] = useState(true)
  const [branch, setBranch] = useState(0)
  const [contentName, setContentName] = useState("")
  const [description, setDescription] = useState("")
  const [thumbnail, setThumbnail] = useState(null)
  const [thumbnailURL, setThumbnailURL] = useState(null)
  const [thumbnailPreview, setThumbnailPreview] = useState(null)
  const [content, setContent] = useState(null)
  const [sweetalert, setSweetAlert] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [fileUploadAlert, setFileUploadAlert] = useState(false)
  const [fileUploadAlertError, setFileUploadAlertError] = useState(false)
  const [videoDuration, setVideoDuration] = useState(0)

  const thumbnailRef = useRef(null)
  const contentRef = useRef(null)
  const videoRef = useRef(null)

  useEffect(() => {
    if (thumbnail) {
      const formData = new FormData()
      formData.append("content", thumbnail)
      return apiPost("file/upload-pub-content", formData, true).then(
        ({ data }) => {
          setThumbnailURL(data)
          setThumbnailPreview(URL.createObjectURL(thumbnail))
          setSweetAlert(true)
        }
      )
    }
    return setThumbnailPreview(null)
  }, thumbnail)

  useEffect(() => {
    if (isVideo && content) {
      videoRef.current.src = URL.createObjectURL(content)
    }
  }, [content])

  function saveContent() {
    setInProgress(true)
    const formData = new FormData()
    formData.append("branch", branch)
    formData.append("thumbnail", thumbnailURL)
    formData.append("name", contentName)
    formData.append("isPaid", isPaid)
    formData.append("isVideo", isVideo)
    formData.append("content", content)
    formData.append("duration", videoDuration)
    formData.append("description", description)
    apiPost("file/upload-content", formData, true)
      .then(() => setFileUploadAlert(true))
      .catch(e => setFileUploadAlertError(true))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dosya Yükleme</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Dosyalar" breadcrumbItem="Yükeleme" />
          <Row>
            <Col lg={12}>
              <Card className="overflow-hidden p-4">
                <div className="bg-soft">
                  <Row className="mb-3">
                    <Col md={3} xs={12}>
                      <Label>Ücretli İçerik</Label>
                      <br />
                      <i className="text-danger">
                        Yüklediğiniz içeriğin ücretli olup olmamasına karar
                        verin!
                      </i>
                      <div className="form-check form-switch form-switch-lg mb-3">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitchsizelg"
                          defaultChecked
                          checked={isPaid}
                          onChange={() => setIsPaid(!isPaid)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={3} xs={12}>
                      <Label>Video İçerik</Label>
                      <br />
                      <i className="text-danger">
                        Yüklediğiniz dosyanın video türünde olduğunu belirtin!
                      </i>
                      <div className="form-check form-switch form-switch-lg mb-3">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitchsizelg"
                          defaultChecked
                          checked={isVideo}
                          onChange={() => setIsVideo(!isVideo)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} md={3}>
                      <Label>Sınıf</Label>
                      <br />
                      <i>İçeriğin geçerli olduğu sınıf numarasını seçiniz!</i>
                      <select
                        className="form-select"
                        onChange={e => {
                          setBranch(parseInt(e.target.value))
                        }}
                      >
                        <option value="0" defaultValue>
                          0
                        </option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} md={3}>
                      <Label>İçeriğin Adı</Label>
                      <Input
                        name="header"
                        className="form-control"
                        placeholder="Dosya için görünecek bir ad belirleyin!"
                        type="text"
                        required
                        value={contentName}
                        onChange={e => setContentName(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} md={3}>
                      <Label>Açıklama</Label>
                      <Input
                        name="header"
                        className="form-control"
                        placeholder="Dosya için açıklama giriniz!"
                        type="text"
                        required
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} md={3}>
                      <Button
                        className="btn btn-md btn-info w-100"
                        onClick={() => {
                          thumbnailRef.current.click()
                        }}
                      >
                        Kapak Fotoğrafı Yükle
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    {thumbnailPreview && (
                      <Col xs={12} md={3}>
                        <center>
                          <img
                            style={{ border: ".5px solid gray" }}
                            src={thumbnailPreview}
                            alt="Önizleme"
                            className="thumbnail mb-3"
                            width={200}
                            height={200}
                          />
                          <br />
                          <Button
                            className="btn btn-md btn-danger w-50"
                            onClick={() => {
                              setThumbnail(null)
                              setThumbnailPreview(null)
                              setThumbnailURL(null)
                            }}
                          >
                            Sil
                          </Button>
                        </center>
                      </Col>
                    )}
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} md={3}>
                      <Button
                        className="btn btn-md btn-primary w-100"
                        onClick={() => {
                          contentRef.current.click()
                        }}
                      >
                        İçerik Yükle
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mb-3">
                    <Col xs={12} md={3}>
                      <Button
                        className={inProgress ? "btn btn-md btn-success w-100 disabled" : "btn btn-md btn-success w-100"}
                        onClick={saveContent}
                      >
                        {inProgress ? "İşleniyor..." : "Kaydet"}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
          <video ref={videoRef} onLoadedMetadata={(e) => setVideoDuration(Math.round(e.target.duration))} hidden></video>
        </Container>
        <input
          hidden={true}
          type="file"
          multiple={false}
          ref={thumbnailRef}
          accept={[".png", ".jpg", ".jpeg"]}
          onChange={e => {
            setThumbnail(e.target.files[0])
            setThumbnailPreview(e.target.result)
          }}
        />
        <input
          hidden={true}
          type="file"
          multiple={false}
          ref={contentRef}
          onChange={e => setContent(e.target.files[0])}
        />
        {sweetalert ? (
          <SweetAlert
            title="Kapak fotoğrafı eklendi"
            timeout={2000}
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
            }}
            showCloseButton={false}
            showConfirm={false}
            success
            onConfirm={() => setSweetAlert(false)}
          ></SweetAlert>
        ) : null}{" "}
        {fileUploadAlert ? (
          <SweetAlert
            title="İçerik yüklendi"
            timeout={2000}
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
            }}
            showCloseButton={false}
            showConfirm={false}
            success
            onConfirm={() => { setInProgress(true); history.push("/files") }}
          ></SweetAlert>
        ) : null}{" "}
        {fileUploadAlertError ? (
          <SweetAlert
            title="İçerik yüklenirken bir hata oluştur!"
            timeout={2000}
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
            }}
            showCloseButton={false}
            showConfirm={false}
            error
            onConfirm={() => setFileUploadAlertError(false)}
          ></SweetAlert>
        ) : null}
      </div>
    </React.Fragment>
  )
}
export default FileUpload
