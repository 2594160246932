import PropTypes from "prop-types"
import React, { useState, useRef, useEffect } from "react"
import { Row, Col, Modal, Input, } from "reactstrap"
import "react-drawer/lib/react-drawer.css"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import { withTranslation } from "react-i18next"
import { apiPost, apiGet } from "helpers/api_helper"

const Header = () => {
  const contentRef = useRef();
  const [content, setContent] = useState();
  const [url, setUrl] = useState("");
  const [mdlIsOpen, setMdlIsOpen] = useState(false);


  const getObjectUrl = async (key) => { const { data } = await apiGet(`file/pubcontent-url/${key}`); return data };

  const uploadFile = async (content) => {
    const formData = new FormData()
    formData.append("content", content)
    return apiPost("file/upload-pub-content", formData, true).then(
      async ({ data }) => {
        setUrl(await getObjectUrl(data))
        setMdlIsOpen(true);
      }
    )
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    body.classList.toggle("vertical-collpsed")
    body.classList.toggle("sidebar-enable")
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => contentRef.current.click()}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-upload" />
              </button>
            </div>
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
      <input
        hidden={true}
        type="file"
        multiple={false}
        ref={contentRef}
        onChange={e => uploadFile(e.target.files[0])}
      />
      <Modal
        size="sm"
        isOpen={mdlIsOpen}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            Önizleme
          </h5>
          <button
            onClick={() => setMdlIsOpen(false)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col lg={12}>
              <Input
                name="header"
                className="form-control"
                placeholder="Dosya URL"
                type="text"
                required
                value={url}
              />
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}
export default withTranslation()(Header)
