import cookie from "js-cookie"

export const getCookie = (key, parse = false) => {
  if (parse) {
    return JSON.parse(cookie.get(key))
  }
  return cookie.get(key)
}

export const setCookie = (name, value, expires, secure, stringify) => {
  cookie.set(name, value, { sameSite: true, expires, secure })
}

export const removeCookie = name => cookie.remove(name)
