import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Modal, Button, Label, Input } from "reactstrap"
import MetaTags from "react-meta-tags"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../styles/datatables.scss"
import { apiGet, apiPost } from "helpers/api_helper"

const ContactIndex = () => {

  const [tableData, setTableData] = useState([])
  const [rowData, setRowData] = useState({})
  const [mdlIsOpen, setMdlIsOpen] = useState(false)
  const [answer, setAnswer] = useState("")


  const actionsBtnGroup = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Row>
          {!row.isAnswered && (
            <Button
              className="btn btn-sm btn-success"
              onClick={() => onRowClick(row)}
            >
              Cevapla
            </Button>
          )}
          {row.isAnswered && (
            <i>Cevaplandı</i>
          )}
        </Row>
      </>
    )
  }

  const answerMessage = () => {
    apiPost(`contact/answer/${rowData._id}`, { answer }, true).then(() => {
      getPosts()
      setMdlIsOpen(false)
    })
  }

  const columns = [
    {
      dataField: "_id",
      text: "Id",
      sort: false,
    },
    {
      dataField: "name",
      text: "Ad - Soyad",
      sort: true,
    },
    {
      dataField: "subject",
      text: "Konu",
      sort: true,
    },
    {
      dataField: "email",
      text: "E-posta",
      sort: true,
    },
    {
      dataField: "actions",
      text: "İşlemler",
      formatter: actionsBtnGroup,
      sort: true,
    },
  ]
  const getPosts = async () => {
    const { data } = await apiGet("contact", true)
    setTableData(data)
  }

  useEffect(getPosts, [0])

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  }

  const onRowClick = (row) => {
    setRowData(tableData.find(item => row._id === item._id))
    setMdlIsOpen(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Esnek Beyin / Blog</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs breadcrumbItem="İletişim Mesajları" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={tableData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={tableData}
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        size="md"
        isOpen={mdlIsOpen}
        toggle={() => setMdlIsOpen(!mdlIsOpen)}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            {(rowData.subject || "") + " - " + rowData.name}
          </h5>
          <button
            onClick={() => setMdlIsOpen(false)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <p>{rowData.message}</p>
          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <Label>Cevap</Label>
              <Input
                type="textarea"
                id="textarea"
                onChange={e => setAnswer(e.target.value)}
                rows="3"
                placeholder="Bir Cevap yazınız"
                value={answer}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>

              <Button
                className="btn btn-md btn-success w-100"
                onClick={answerMessage}
              >
                Cevapla
              </Button>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col md={12}>

              <Button
                className="btn btn-md btn-danger w-100"
                onClick={() => setMdlIsOpen(false)}
              >
                İptal
              </Button>
            </Col>
          </Row>
          <br></br>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ContactIndex
