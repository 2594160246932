import React from "react"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

import logo from "../../assets/images/companies/img-4.png"

const Sidebar = props => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="" height="45" />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="" height="45" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logo} alt="" height="45" />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="" height="45" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {<SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(Sidebar))
