import React, { useEffect } from "react"

import { withRouter } from "react-router-dom"

// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import { leftSideBarThemeTypes, leftSidebarTypes } from "constants/layout"

const Layout = props => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <Sidebar
          theme={leftSideBarThemeTypes.DARK}
          type={leftSidebarTypes.DEFAULT}
          isMobile={isMobile}
        />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default withRouter(Layout)
