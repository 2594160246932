import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, Label, Badge, Modal } from "reactstrap"
import MetaTags from "react-meta-tags"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../styles/datatables.scss"
import { apiGet } from "helpers/api_helper"
import { useHistory } from "react-router-dom"

const EventsIndex = () => {
  const history = useHistory()
  const [tableData, setTableData] = useState([])
  const [userList, setUserList] = useState([])
  const [mdlInfoIsOpen, setInfoMdlIsOpen] = useState(false)
  const actionsBtnGroup = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Row className="mb-2">
          <Button
            className="btn btn-sm btn-dark"
            onClick={() => {
              apiGet(`events/attendees/${row._id}`).then(({ data }) => { setUserList(data); setInfoMdlIsOpen(true); })
            }}
          >
            Katılımcı Listesi
          </Button>
        </Row>
        <Row className="mb-2">
          <Button
            className="btn btn-sm btn-info"
            onClick={() => {
              history.push(`/events/editor/${row._id}`, {
                id: row._id,
              })
            }}
          >
            Güncelle
          </Button>
        </Row>
        {row.isActive === true &&
          <Row className="mb-2">
            <Button
              className="btn btn-sm btn-warning"
              onClick={() => {
                apiGet(`events/deactivate/${row._id}`).then(() => getEvents())
              }}
            >
              Yayından kaldır
            </Button>
          </Row>
        }
        {!row.isActive &&
          <Row className="mb-2">
            <Button
              className="btn btn-sm btn-success"
              onClick={() => {
                apiGet(`events/activate/${row._id}`).then(() => getEvents())
              }}
            >
              Yayınla
            </Button>
          </Row>
        }
      </>
    )
  }

  const getEvents = async () => {
    const { data } = await apiGet("events/for-admin", true)
    setTableData(data)
  }

  useEffect(getEvents, [0])

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length,
    custom: true,
  }
  const columns = [
    {
      dataField: "_id",
      text: "Id",
      sort: false,
    },
    {
      dataField: "header",
      text: "Başlık",
      sort: true,
    },
    {
      dataField: "attendees",
      text: "Katılımcı Sayısı",
      sort: true,
    },
    {
      dataField: "actions",
      text: "İşlemler",
      formatter: actionsBtnGroup,
      sort: true,
    },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Esnek Beyin / Etkinlikler</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Etkinlikler" />

          <Row>
            <Col className="col-12">
              <a href="/events/editor" className="btn btn-success w-sm mb-3">
                Yeni Etkinlik
              </a>
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={tableData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={tableData}
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
          size="lg"
          isOpen={mdlInfoIsOpen}
          toggle={() => setInfoMdlIsOpen(!mdlInfoIsOpen)}
        >
          <div className="modal-header">
            <h5
              className="modal-title mt-0 text-uppercase"
              id="myExtraLargeModalLabel"
            >
              Katılımcı Listesi
            </h5>
            <button
              onClick={() => setInfoMdlIsOpen(false)}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row text-center">
              {userList.map((user, index) => {
                return (<div className="row">
                  <div className="col-md-12">
                    <p> {index + 1}) İsim : {user.name} Telefon: {user.phone} Veli İsmi: {user.guardianName} Veli Telefon: {user.guardianPhone}</p>
                  </div>
                </div>)
              })}
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default EventsIndex
