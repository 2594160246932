import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Modal, Button, Badge } from "reactstrap"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider from "react-bootstrap-table2-toolkit"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../styles/datatables.scss"
import { apiGet, apiPost } from "helpers/api_helper"

const UserIndex = () => {
  const [tableData, setTableData] = useState([])
  const [quizResults, setQuizResults] = useState([])
  const [rowData, setRowData] = useState({})
  const [mdlInfoIsOpen, setInfoMdlIsOpen] = useState(false)
  const [mdlPwdIsOpen, setPwdMdlIsOpen] = useState(false)
  const [userPwd, setUserPwd] = useState("")

  const actionsBtnGroup = (cell, row) => {
    return (
      <>
        <Row className="mb-2">
          <Button
            className="btn btn-sm btn-info"
            onClick={() => {
              setRowData(tableData.find(item => row._id === item._id))
              setInfoMdlIsOpen(true)
            }}
          >
            Görüntüle
          </Button>
        </Row>
        <Row className="mb-2">
          {row.status === 1001001 && (
            <Button
              className="btn btn-sm btn-danger"
              onClick={() => {
                apiGet(`user/admin-ban-user/${row._id}`).then(({ data }) =>
                  data ? getUsers() : () => { }
                )
              }}
            >
              Kapat
            </Button>
          )}
          {[1112111, 1002001].includes(row.status) && (
            <Button
              className="btn btn-sm btn-success"
              onClick={() => {
                apiGet(`user/admin-activate-user/${row._id}`).then(({ data }) =>
                  data ? getUsers() : () => { }
                )
              }}
            >
              Aktifleştir
            </Button>
          )}
        </Row>
        <Row className="mb-2">
          <Button
            className="btn btn-sm btn-secondary"
            onClick={() => {
              setRowData(tableData.find(item => row._id === item._id))
              setPwdMdlIsOpen(true)
            }}
          >
            Şifre Değiştir
          </Button>
        </Row>
      </>
    )
  }

  const changePassword = () =>
    new Promise((res, rej) => {
      apiPost(`user/admin-change-user-pwd/${rowData._id}`, {
        password: userPwd,
      }).then(() => {
        setPwdMdlIsOpen(false)
        setUserPwd("")
        getUsers()
      })
    })

  const statusGroup = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {row.status === 1001001 && (
          <p className="text-success text-lg">Aktif</p>
        )}
        {row.status === 1002001 && (
          <p className="text-warning text-lg">Pasif</p>
        )}
        {row.status === 1112111 && (
          <p className="text-danger text-lg">Kapalı</p>
        )}
      </>
    )
  }

  const columns = [
    {
      dataField: "name",
      text: "Ad - Soyad",
      sort: false,
    },
    {
      dataField: "phone",
      text: "Telefon",
      sort: true,
    },
    {
      dataField: "schoolName",
      text: "Okul Adı",
      sort: true,
    },
    {
      dataField: "branch",
      text: "Şube",
      sort: true,
    },
    {
      dataField: "guardianName",
      text: "Veli Adı",
      sort: true,
    },
    {
      dataField: "guardianPhone",
      text: "Veli Telefon",
      sort: true,
    },
    {
      dataField: "status",
      text: "Durum",
      formatter: statusGroup,
    },
    {
      text: "İşlemler",
      formatter: actionsBtnGroup,
    },
  ]
  const getUsers = async () => {
    const { data } = await apiGet("user/admin-get-users", true)
    setTableData(data)
  }


  const getQuizResults = async () => {
    const { data } = await apiGet("quiz/", true)
    setQuizResults(data)
  }

  useEffect(() => {
    getQuizResults()
    getUsers()
  }, [0])

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: tableData.length },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const onRowClick = (e, row, rowIndex) => {
    setRowData(tableData.find(item => row._id === item._id))
    setInfoMdlIsOpen(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Esnek Beyin / Users</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs breadcrumbItem="Kullanıcılar" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={tableData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={tableData}
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        size="md"
        isOpen={mdlInfoIsOpen}
        toggle={() => setInfoMdlIsOpen(!mdlInfoIsOpen)}
      >
        <div className="modal-header">
          <h5
            className="modal-title mt-0 text-uppercase"
            id="myExtraLargeModalLabel"
          >
            {rowData.name}
          </h5>
          <button
            onClick={() => setInfoMdlIsOpen(false)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="row">
              <div className="col-md-4">
                <p>Ad - Soyad :</p>
              </div>
              <div className="col-md-8">
                <p>{rowData.name}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <p>Telefon :</p>
              </div>
              <div className="col-md-8">
                <p>{rowData.phone}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <p>E-posta :</p>
              </div>
              <div className="col-md-8">
                <p>{rowData.email}</p>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-4">
                <p>Veli Ad - Soyad :</p>
              </div>
              <div className="col-md-8">
                <p>{rowData.guardianName}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <p>Veli Telefon :</p>
              </div>
              <div className="col-md-8">
                <p>{rowData.guardianPhone}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <p>Veli E-Posta :</p>
              </div>
              <div className="col-md-8">
                <p>{rowData.guardianEmail}</p>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-4">
                <p>Okul Adı :</p>
              </div>
              <div className="col-md-8">
                <p>{rowData.schoolName}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <p>Sınıf :</p>
              </div>
              <div className="col-md-8">
                <p>{rowData.branch}</p>
              </div>
            </div>
            <hr />
            <h5>Test Sonuçları</h5>
            <div className="row">
              {quizResults.filter((item) => item.userId === rowData._id).map((item) => {
                return (<>
                  <div className="row">
                    <div className="col-md-4">
                      <p>Test Adı :</p>
                    </div>
                    <div className="col-md-8">
                      <p>{item.quizName}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <p>Tarih :</p>
                    </div>
                    <div className="col-md-8">
                      <p>{new Date(item.createdAt).toLocaleDateString()}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <p>Sonuç :</p>
                    </div>
                    <div className="col-md-8">
                      <p>{item.ratio}</p>
                    </div>
                  </div>
                  <hr />
                </>)
              })}
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        size="md"
        isOpen={mdlPwdIsOpen}
        toggle={() => setPwdMdlIsOpen(!mdlPwdIsOpen)}
      >
        <div className="modal-header">
          <h5
            className="modal-title mt-0 text-uppercase"
            id="myExtraLargeModalLabel"
          >
            {rowData.name}
          </h5>
          <button
            onClick={() => setPwdMdlIsOpen(false)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-3">Yeni Şifre :</div>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Yeni Şifre"
                value={userPwd}
                onChange={e => setUserPwd(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 pull-right">
              <button className="btn btn-md btn-info" onClick={changePassword}>
                Değiştir
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default UserIndex
