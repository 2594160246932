import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody, Label, Input, Modal, Button } from "reactstrap"
import { EditorState, convertToRaw } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import SweetAlert from "react-bootstrap-sweetalert"
import { apiPost } from "../../helpers/api_helper"
import { isUrl } from "helpers/general"

const QuestionEditor = () => {
  const history = useHistory()
  const [sweetAlert, setSweetAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [mdlInfoIsOpen, setInfoMdlIsOpen] = useState(false)

  const [questionState, setQuestionState] = useState(EditorState.createEmpty())
  const [trueAnswer, setTrueAnswer] = useState("")
  const [falseAnswer_1, setFalseAnswer_1] = useState("")
  const [falseAnswer_2, setFalseAnswer_2] = useState("")
  const [falseAnswer_3, setFalseAnswer_3] = useState("")
  const [falseAnswer_4, setFalseAnswer_4] = useState("")
  const [branch, setBranch] = useState(0)

  const onSuccess = () => {
    // setSweetAlert(false)
    history.push("/questions")
  }

  const uploadCallback = async (file) => {
    const formData = new FormData()
    formData.append("content", file)
    return apiPost("file/upload-pub-content", formData, true).then(
      ({ data }) => {
        // setThumbnailURL(data)
        // setThumbnailPreview(URL.createObjectURL(thumbnail))
        // setSweetAlert(true)
        console.log(data)
        return data;
      }
    )
  }
  const saveQuestion = () => {
    const data = {
      question: draftToHtml(convertToRaw(questionState.getCurrentContent())),
      branch,
      answers: [
        { isTrue: true, text: trueAnswer, tag: "" },
        { isTrue: false, text: falseAnswer_1, tag: "" },
        { isTrue: false, text: falseAnswer_2, tag: "" },
        { isTrue: false, text: falseAnswer_3, tag: "" },
        { isTrue: false, text: falseAnswer_4, tag: "" },
      ],
    }
    let tags = ["A", "B", "C", "D", "E"]
    for (let i = 0; i < 5 * data.answers.length; i++) {
      data.answers = data.answers.sort(() => Math.random() - 0.5)
    }
    data.answers[0].tag = tags[0]
    data.answers[1].tag = tags[1]
    data.answers[2].tag = tags[2]
    data.answers[3].tag = tags[3]
    data.answers[4].tag = tags[4]

    apiPost("question/save-question", data, true)
      .then(() => setSweetAlert(true))
      .catch(e => setSweetAlert(e.response.data))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Esnek Beyin / Sorular</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Soru Oluştur" />
          <Row className="mb-3">
            <Col className="col-6">
              <h4>Sınıf</h4>
              <Label className="text-danger">
                (* 0 her sınıfta geçerli anlamına gelir)
              </Label>
              <br />
              <Label className="text-danger">
                (* Sınıf numarası örn: 1 5 6 7 11)
              </Label>
              <Input
                name="header"
                value={branch}
                className="form-control"
                placeholder="Sınıf numarası"
                type="number"
                required
                onChange={e => setBranch(parseInt(e.target.value))}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-12">
              <Card>
                <CardBody>
                  <h4>Soru</h4>
                  <Editor
                    toolbar={{
                      image: {
                        uploadCallback: uploadCallback, previewImage: true,
                        alt: { present: true, mandatory: false },
                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                      }
                    }}
                    editorState={questionState}
                    editorStyle={{ height: "350px" }}
                    wrapperStyle={{ height: "420px" }}
                    onEditorStateChange={editorState => {
                      setQuestionState(editorState)
                    }}
                  >
                    <textarea
                      disabled
                      value={draftToHtml(
                        convertToRaw(questionState.getCurrentContent())
                      )}
                    />
                  </Editor>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={4}>
              <Label>Doğru Cevap</Label>
              <Input
                type="textarea"
                id="textarea"
                onChange={e => setTrueAnswer(e.target.value)}
                maxLength="225"
                rows="3"
                placeholder="Doğru olan cevabı buraya yazınız!"
                value={trueAnswer}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={4}>
              <Label>Yanlış Cevap</Label>
              <Input
                type="textarea"
                id="textarea"
                onChange={e => setFalseAnswer_1(e.target.value)}
                maxLength="225"
                rows="3"
                placeholder="Yanlış olan cevabı buraya yazınız!"
                value={falseAnswer_1}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={4}>
              <Label>Yanlış Cevap</Label>
              <Input
                type="textarea"
                id="textarea"
                onChange={e => setFalseAnswer_2(e.target.value)}
                maxLength="225"
                rows="3"
                placeholder="Yanlış olan cevabı buraya yazınız!"
                value={falseAnswer_2}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={4}>
              <Label>Yanlış Cevap</Label>
              <Input
                type="textarea"
                id="textarea"
                onChange={e => setFalseAnswer_3(e.target.value)}
                maxLength="225"
                rows="3"
                placeholder="Yanlış olan cevabı buraya yazınız!"
                value={falseAnswer_3}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={4}>
              <Label>Yanlış Cevap</Label>
              <Input
                type="textarea"
                id="textarea"
                onChange={e => setFalseAnswer_4(e.target.value)}
                maxLength="225"
                rows="3"
                placeholder="Yanlış olan cevabı buraya yazınız!"
                value={falseAnswer_4}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={2}>
              <Input
                type="button"
                value="Kaydet"
                className="btn btn-success mb-3"
                onClick={saveQuestion}
              />
            </Col>{" "}
            <Col xs={12} md={2}>
              <Input
                type="button"
                value="Önizle"
                className="btn btn-warning mb-3"
                onClick={() => setInfoMdlIsOpen(true)}
              />
            </Col>
          </Row>
        </div>
      </div>
      {
        sweetAlert ? (
          <SweetAlert
            title="Soru kaydedildi!"
            timeout={2000}
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
            }}
            showCloseButton={false}
            showConfirm={false}
            success
            onConfirm={onSuccess}
          ></SweetAlert>
        ) : null
      }
      {
        errorAlert ? (
          <SweetAlert
            title={errorAlert.message}
            timeout={2000}
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
            }}
            showCloseButton={false}
            showConfirm={false}
            error
            onConfirm={() => {
              setErrorAlert(undefined)
            }}
          ></SweetAlert>
        ) : null
      }
      <Modal
        size="md"
        isOpen={mdlInfoIsOpen}
        toggle={() => setInfoMdlIsOpen(!mdlInfoIsOpen)}
      >
        <div className="modal-header">
          <h5
            className="modal-title mt-0 text-uppercase"
            id="myExtraLargeModalLabel"
          >
            Önizleme
          </h5>
          <button
            onClick={() => setInfoMdlIsOpen(false)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Row>
              <Col md={12}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: draftToHtml(
                      convertToRaw(questionState.getCurrentContent())
                    ),
                  }}
                ></div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={12}>
                <p>
                  <i className="text-success">A)</i>
                  {trueAnswer}
                  {isUrl(falseAnswer_3) && (
                    <figure className="mb-6">
                      <img
                        className="img-fluid"
                        src={trueAnswer}
                        alt=""
                        style={{ width: '400px' }}
                      />
                    </figure>
                  )}
                </p>
                <p>
                  <i className="text-danger">B)</i>
                  {falseAnswer_1}
                  {isUrl(falseAnswer_3) && (
                    <figure className="mb-6">
                      <img
                        className="img-fluid"
                        src={falseAnswer_1}
                        alt=""
                        style={{ width: '400px' }}
                      />
                    </figure>
                  )}
                </p>
                <p>
                  <i className="text-danger">C)</i>
                  {falseAnswer_2}
                  {isUrl(falseAnswer_2) && (
                    <figure className="mb-6">
                      <img
                        className="img-fluid"
                        src={trueAnswer}
                        alt=""
                        style={{ width: '400px' }}
                      />
                    </figure>
                  )}
                </p>
                <p>
                  <i className="text-danger">D)</i>
                  {falseAnswer_3}
                  {isUrl(falseAnswer_3) && (
                    <figure className="mb-6">
                      <img
                        className="img-fluid"
                        src={trueAnswer}
                        alt=""
                        style={{ width: '400px' }}
                      />
                    </figure>
                  )}
                </p>
                <p>
                  <i className="text-danger">E)</i>
                  {falseAnswer_4}
                  {isUrl(falseAnswer_4) && (
                    <figure className="mb-6">
                      <img
                        className="img-fluid"
                        src={trueAnswer}
                        alt=""
                        style={{ width: '400px' }}
                      />
                    </figure>
                  )}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Input
                  type="button"
                  value="Kaydet"
                  className="btn btn-success mb-3"
                  onClick={saveQuestion}
                />
              </Col>{" "}
            </Row>
          </Row>
        </div>
      </Modal>
    </React.Fragment >
  )
}

export default QuestionEditor
