import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Badge, Button, Card, CardBody, Col, Row } from "reactstrap"
import { apiGet, apiPost } from "helpers/api_helper"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { useHistory } from "react-router-dom"

const PackagesIndex = () => {
  const history = useHistory()
  const [tableData, setTableData] = useState([])
  const [proccessedId, setProccessedId] = useState('')

  const getOrders = () => {
    apiGet("order/")
      .then(({ data }) => {
        setTableData(data)
      })
      .catch(error => console.log(error))
  }

  useEffect(() => getOrders(), [0])

  const typeRender = (cell, row, rowIndex, formatExtraData) => {
    if (row.status === 0) {
      return <strong className="text-warning" style={{ fontSize: "16px" }}>Onay Bekliyor</strong>
    } else if (row.status === 1) {
      return <strong className="text-success" style={{ fontSize: "16px" }}>Onaylandı</strong>
    }
    return <strong className="text-danger" style={{ fontSize: "16px" }}>İptal Edildi</strong>
  }

  const actionsBtnGroup = (cell, row, rowIndex, formatExtraData) => {
    if (row.status !== 1 && row.status !== 2) {
      return (
        <>
          <Row>
            <Col md={12}>
              <Button
                className={proccessedId !== row._id ? "btn btn-sm btn-success w-100" : "btn btn-sm btn-success w-100 disabled" }
                onClick={() => {
                  apiPost(`order/activate/${row._id}`, null, true).then(() =>
                    getOrders()
                  )
                }}
              >
                Onayla
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Button
                className="btn btn-sm btn-danger w-100 mt-3"
                onClick={() => {
                  apiPost(`order/cancel/${row._id}`, null, true).then(() =>
                    getOrders()
                  )
                }}
              >
                İptal Et
              </Button>
            </Col>
          </Row>
        </>
      )
    }
  }

const columns = [
  {
    dataField: "code",
    text: "Sipariş No",
    sort: false,
  },
  {
    dataField: "pkg.name",
    text: "Paket Adı",
    sort: true,
  },
  {
    dataField: "user.name",
    text: "ALıcı Adı - Soyadı",
    sort: true,
  },
  {
    dataField: "user.email",
    text: "ALıcı E-posta",
    sort: true,
  },
  {
    dataField: "pkg.price",
    text: "Satış Fiyatı",
    sort: true,
  },
  {
    dataField: "createdAt",
    text: "Sipariş Tarihi",
    sort: true,
    formatter: (cell, row) => {
      return (new Date(row.createdAt).toLocaleDateString())
    }
  },
  {
    dataField: "status",
    text: "Durum",
    sort: true,
    formatter: typeRender,
  },
  {
    dataField: "actions",
    text: "İşlemler",
    sort: true,
    formatter: actionsBtnGroup,
  },
]
const defaultSorted = [
  {
    dataField: "id",
    order: "asc",
  },
]
const pageOptions = {
  sizePerPage: 10,
  totalSize: tableData.length,
  custom: true,
}

return (
  <React.Fragment>
    <div className="page-content">
      <MetaTags>
        <title>Esnek Beyin / Siparişler</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumbs title="Siparişler" breadcrumbItem="Tümü" />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={columns}
                  data={tableData}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={tableData}
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  </React.Fragment>
)
}

export default PackagesIndex
