import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  Modal,
  Label,
} from "reactstrap"
import { withTranslation } from "react-i18next"

const Dashboard = props => {
  const [videoUrl, setVideoUrl] = useState()
  const [mdlIsOpen, setMdlIsOpen] = useState(false)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Esnek Beyin</title>
        </MetaTags>
        <Container fluid>
          <h4>{props.t("Esnek Beyin Yönetim Paneli")}</h4>
          <Row className={"mt-4"}>
            <Col>
              <h5>{props.t("Öğretici Videolar")}</h5>
            </Col>
          </Row>
          <Row className={"mt-4"}>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={7}>
                      <Row>
                        <Col>
                          <strong>
                            <p>
                              Sistemde bulunan blog yazılarını nasıl
                              görüntüleyebilirim ?
                            </p>
                          </strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ul>
                            <li>
                              <a href="/blog">Adres</a>
                            </li>
                            <li>
                              Menüden “Blog” butonuna tıklayarak veya yukarıdaki
                              adrese erişerek görüntüleyebilirsiniz.
                            </li>
                            <li>
                              İşlemler sekmesinde, yazıyı güncelleyebilir veya
                              yayınlayabilirsiniz.
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <center
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setVideoUrl("/videos/2.mp4")
                          setMdlIsOpen(true)
                        }}
                      >
                        İzlemek için tıklayınız;
                        <div>
                          <img
                            className={"pull-left"}
                            src={"/video_thumbnail_default.png"}
                            width={100}
                            height={100}
                          ></img>
                        </div>
                      </center>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className={"mt-4"}>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={7}>
                      <Row>
                        <Col>
                          <strong>
                            <p>Sisteme yeni blog yazısı nasıl eklenir ?</p>
                          </strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ul>
                            <li>
                              <a href="/blog">Adres</a>
                            </li>
                            <li>
                              Yukarıdaki adrese eriştikten sonra veya “Blog”
                              sayfasındaki “Yeni Yazı” butonuna tıklayınız.
                            </li>
                            <li>
                              Açılan sayfada, Başlık, Özet ve Yazı alanını
                              doldurunuz.
                            </li>
                            <li>Emin olduğunuzda kaydete basınız.</li>
                          </ul>
                          <i>
                            Not: Her yazı ilk kaydedildiğinde “Taslak” olarak
                            eklenecektir.
                          </i>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <center
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setVideoUrl("/videos/3.mp4")
                          setMdlIsOpen(true)
                        }}
                      >
                        İzlemek için tıklayınız;
                        <div>
                          <img
                            className={"pull-left"}
                            src={"/video_thumbnail_default.png"}
                            width={100}
                            height={100}
                          ></img>
                        </div>
                      </center>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className={"mt-4"}>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={7}>
                      <Row>
                        <Col>
                          <strong>
                            <p>
                              Sistemdeki mesajları nasıl görüntüleyebilirim ?
                            </p>
                          </strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ul>
                            <li>
                              <a href="/contact">Adres</a>
                            </li>
                            <li>
                              Web sayfasından paylaşılan mesajlara yukarıdaki
                              adrese erişerek ulaşabilirsiniz.
                            </li>
                            <li>
                              Listedeki mesajların içeriğini görüntüleyebilmek
                              için üzerine tıklamanız yeterlidir.
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <center
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setVideoUrl("/videos/4.mp4")
                          setMdlIsOpen(true)
                        }}
                      >
                        İzlemek için tıklayınız;
                        <div>
                          <img
                            className={"pull-left"}
                            src={"/video_thumbnail_default.png"}
                            width={100}
                            height={100}
                          ></img>
                        </div>
                      </center>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className={"mt-4"}>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={7}>
                      <Row>
                        <Col>
                          <strong>
                            <p>
                              Sistemdeki kayıtlı kullanıcılara nerden
                              erişebilirim ?
                            </p>
                          </strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ul>
                            <li>
                              <a href="/users">Adres</a>
                            </li>
                            <li>
                              Yukarıdaki adresten veya menüdeki “Kullanıcılar”
                              butonu aracılığıyla ulaşabilirsiniz.
                            </li>
                            <li>
                              Bir üyenin detayını görüntüleyebilmek için
                              işlemler altındaki “Görüntüle” butonuna
                              tıklayınız.
                            </li>
                            <li>
                              Bir üyenin şifresini değiştirmek için işlemler
                              altındaki “Şifre Değiştir” butonuna tıklayınız.
                            </li>
                            <li>
                              Bir üyenin hesabını dondurmak / aktifleştirmek
                              için işlemler altındaki “Kapat / Aktifleştir”
                              butonuna tıklayınız.
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <center
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setVideoUrl("/videos/5.mp4")
                          setMdlIsOpen(true)
                        }}
                      >
                        İzlemek için tıklayınız;
                        <div>
                          <img
                            className={"pull-left"}
                            src={"/video_thumbnail_default.png"}
                            width={100}
                            height={100}
                          ></img>
                        </div>
                      </center>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className={"mt-4"}>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={7}>
                      <Row>
                        <Col>
                          <strong>
                            <p>
                              Sistemdeki sınav sorularını nasıl
                              görüntüleyebilirim ?
                            </p>
                          </strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ul>
                            <li>
                              <a href="/questions">Adres</a>
                            </li>
                            <li>
                              Menüdeki “Sorular” butonuna tıklayarak veya
                              yukarıdaki adrese erişerek soruları
                              görüntüleyebilirsiniz.
                            </li>
                            <li>
                              Bir sorunun içeriğini görüntülemek için işlemler
                              altındaki “Önizle” butonuna tıklayınız.
                            </li>
                            <li>
                              Açılan ekranda sorunun nasıl göründüğünü ve
                              şıklarını inceleyebilirsiniz.
                            </li>
                          </ul>
                          <i>
                            Not: Kaygı testi için oluşturulan sorularda doğru
                            şık bulunmadığı için “A) Evet” ve “B) Hayır” olarak
                            belirlenmiştir.
                          </i>
                          <i>
                            Not: Klasik Test Sorularında ise şıklar içerisinde
                            doğru olan otomatik olarak belirlenmektedir.
                          </i>
                          <ul>
                            <li>
                              İşlemler altındaki “Pasifleştir / Aktifleştir”
                              butonuna tıklayarak, bir soruyu ileride
                              hazırlayacağınız sınavlar için devre dışı
                              bırakabilir veya aktifleştirebilirsiniz.
                            </li>
                            <li>
                              Her soru oluşturulurken bir sınıfa atanır ve
                              filtrelemek için “Sınıf” altındaki değerlerini
                              seçebilirsiniz.
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <center
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setVideoUrl("/videos/6.mp4")
                          setMdlIsOpen(true)
                        }}
                      >
                        İzlemek için tıklayınız;
                        <div>
                          <img
                            className={"pull-left"}
                            src={"/video_thumbnail_default.png"}
                            width={100}
                            height={100}
                          ></img>
                        </div>
                      </center>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className={"mt-4"}>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={7}>
                      <Row>
                        <Col>
                          <strong>
                            <p>Sisteme nasıl soru eklenir ?</p>
                          </strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>
                            Sistemde iki tür soru çeşidi bulunmaktadır. Klasik
                            sınav türleri için (Değerlendirme, Sınav, Test)
                            aşağıdaki adrese erişebilir veya “Sorular”
                            ekranındaki “Klasik Soru Oluştur” butonuna
                            tıklayabilirsiniz.
                          </p>
                          <ul>
                            <li>
                              <a href="/questions/editor">Adres</a>
                            </li>
                            <li>
                              Sorunun geçerli olacağı sınıf numarasını seçtikten
                              sonra editör içerisine sorunuzu yazınız ve alttaki
                              şıkları doldurunuz.
                            </li>
                            <li>İlk alan doğru cevaplar için ayırılmıştır.</li>
                            <li>
                              Her soru için doğru cevap şıkkı rastgele olarak
                              belirlenmektir.
                            </li>
                            <li>
                              Emin oldğunuzda “Önizle” veya “Kaydet” butonlarına
                              tıklayabilirsiniz.
                            </li>
                          </ul>
                          <p>
                            Kaygı sınavı için aşağıdaki adresine ulaşabilir veya
                            “Sorular” ekranındaki “Kaygı Sorusu Oluştur”
                            butonuna tıklayabilirsiniz.
                          </p>
                          <ul>
                            <li>
                              <a href="/questions/anxiety-editor">Adres</a>
                            </li>
                            <li>
                              Sorunun geçerli olacağı sınıf numarasını seçtikten
                              sonra editör içerisine sorunuzu yazınız.
                            </li>
                            <li>
                              Emin oldğunuzda “Önizle” veya “Kaydet” butonlarına
                              tıklayabilirsiniz.
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <center
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setVideoUrl("/videos/7.mp4")
                          setMdlIsOpen(true)
                        }}
                      >
                        İzlemek için tıklayınız;
                        <div>
                          <img
                            className={"pull-left"}
                            src={"/video_thumbnail_default.png"}
                            width={100}
                            height={100}
                          ></img>
                        </div>
                      </center>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className={"mt-4"}>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={7}>
                      <Row>
                        <Col>
                          <strong>
                            <p>
                              Sistemdeki sınavları nasıl görüntüleyebilirim ?
                            </p>
                          </strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ul>
                            <li>
                              <a href="/quizes">Adres</a>
                            </li>
                            <li>
                              Yukarıdaki adrese erişerek veya menüdeki
                              “Sınavlar” butonuna tıklayarak sistemde bulunan
                              sınavları görüntüleyebilirsiniz.
                            </li>
                            <li>
                              Oluşturduğunuz sınavın içeriğini görüntüleyebilmek
                              için “Önizle” butonuna tıklayınız.
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <center
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setVideoUrl("/videos/8.mp4")
                          setMdlIsOpen(true)
                        }}
                      >
                        İzlemek için tıklayınız;
                        <div>
                          <img
                            className={"pull-left"}
                            src={"/video_thumbnail_default.png"}
                            width={100}
                            height={100}
                          ></img>
                        </div>
                      </center>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className={"mt-4"}>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={7}>
                      <Row>
                        <Col>
                          <strong>
                            <p>
                              Sistemdeki sınavları nasıl görüntüleyebilirim ?
                            </p>
                          </strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ul>
                            <li>
                              <a href="/quizes">Adres</a>
                            </li>
                            <li>
                              Yukarıdaki adrese erişerek veya menüdeki
                              “Sınavlar” butonuna tıklayarak sistemde bulunan
                              sınavları görüntüleyebilirsiniz.
                            </li>
                            <li>
                              Oluşturduğunuz sınavın içeriğini görüntüleyebilmek
                              için “Önizle” butonuna tıklayınız.
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <center
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setVideoUrl("/videos/8.mp4")
                          setMdlIsOpen(true)
                        }}
                      >
                        İzlemek için tıklayınız;
                        <div>
                          <img
                            className={"pull-left"}
                            src={"/video_thumbnail_default.png"}
                            width={100}
                            height={100}
                          ></img>
                        </div>
                      </center>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className={"mt-4"}>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={7}>
                      <Row>
                        <Col>
                          <strong>
                            <p>Yeni bir kaygı testi nasıl oluşturulur ?</p>
                          </strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ul>
                            <li>
                              <a href="/quizes/editor">Adres - Klasik Test</a>
                            </li>

                            <li>
                              <a href="/quizes/anxiety-editor">
                                Adres - Kaygı Testi
                              </a>
                            </li>
                            <li>
                              Yukarıdaki adrese erişerek veya Sınavlar
                              ekranındaki “Kaygı Testi Oluştur” butonuna
                              tıklayarak yeni bir sınav oluşturabilirsiniz.
                            </li>
                            <li>
                              Gerekli alanları doldurduktan sonra sol tarafta
                              bulunan listeden, eklemek istediğiniz soruları
                              “Ekle” butonuna basarak sağ taraftaki listeye
                              ekleyebilirsiniz.
                            </li>
                            <li>
                              Eklediğiniz soruları çıkarmak için sağ taraftaki
                              listede bulunan “Çıkar” butonuna tıklayınız.
                            </li>
                            <li>
                              Emin olduğunuzda “Oluştur” deyiniz ve açılan
                              ekranda “Kaydet” butonuna tıklayınız
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <center
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setVideoUrl("/videos/9.mp4")
                          setMdlIsOpen(true)
                        }}
                      >
                        İzlemek için tıklayınız;
                        <div>
                          <img
                            className={"pull-left"}
                            src={"/video_thumbnail_default.png"}
                            width={100}
                            height={100}
                          ></img>
                        </div>
                      </center>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        size="lg"
        isOpen={mdlIsOpen}
        toggle={() => setMdlIsOpen(!mdlIsOpen)}
      >
        <div className="modal-header">
          <button
            onClick={() => setMdlIsOpen(false)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <video
            autoPlay
            style={{ width: "100%" }}
            controls={true}
            src={videoUrl}
          ></video>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
