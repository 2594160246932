import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Modal } from "reactstrap"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../styles/datatables.scss"
import { apiGet } from "helpers/api_helper"

const FeedbackIndex = () => {
  const [tableData, setTableData] = useState([])
  const [rowData, setRowData] = useState({})
  const [mdlIsOpen, setMdlIsOpen] = useState(false)
  const columns = [
    {
      dataField: "name",
      text: "Ad - Soyad",
      sort: true,
    },
    {
      dataField: "email",
      text: "E-posta",
      sort: true,
    },
    {
      dataField: "message",
      text: "Mesaj",
      sort: true,
    },
  ]
  const getFeedbacks = async () => {
    const { data } = await apiGet("contact/feedbacks", true)
    setTableData(data)
  }

  useEffect(getFeedbacks, [0])

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: tableData.length },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const onRowClick = (e, row, rowIndex) => {
    setRowData(tableData.find(item => row._id === item._id))
    setMdlIsOpen(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Esnek Beyin / Blog</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs breadcrumbItem="Geri Bildirimler" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={tableData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={tableData}
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    rowEvents={{ onClick: onRowClick }}
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FeedbackIndex
