import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody, Label, Input, Modal } from "reactstrap"
import { EditorState, convertToRaw } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import SweetAlert from "react-bootstrap-sweetalert"
import { apiPost } from "../../helpers/api_helper"

const AnxietyQuestionEditor = () => {
  const history = useHistory()
  const [sweetAlert, setSweetAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [mdlInfoIsOpen, setInfoMdlIsOpen] = useState(false)

  const [questionState, setQuestionState] = useState(EditorState.createEmpty())
  const [branch, setBranch] = useState(0)

  const onSuccess = () => {
    // setSweetAlert(false)
    history.push("/questions")
  }

  const saveQuestion = () => {
    const data = {
      question: draftToHtml(convertToRaw(questionState.getCurrentContent())),
      branch,
    }
    apiPost("question/save-anxiety-question", data, true)
      .then(() => setSweetAlert(true))
      .catch(e => setSweetAlert(e.response.data))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Esnek Beyin / Sorular</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Soru Oluştur" />
          <Row className="mb-3">
            <Col className="col-6">
              <h4>Sınıf</h4>
              <Label className="text-danger">
                (* 0 her sınıfta geçerli anlamına gelir)
              </Label>
              <br />
              <Label className="text-danger">
                (* Sınıf numarası örn: 1 5 6 7 11)
              </Label>
              <br />
              <Label className="text-danger">
                (* Kaygı testi için eklediğiniz sorularda cevaplar otomatik
                oluşturulur ve "Evet" | "Hayır" şeklindedir.)
              </Label>
              <Input
                name="header"
                value={branch}
                className="form-control"
                placeholder="Sınıf numarası"
                type="number"
                required
                onChange={e => setBranch(parseInt(e.target.value))}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-12">
              <Card>
                <CardBody>
                  <h4>Soru</h4>
                  <Editor
                    editorState={questionState}
                    editorStyle={{ height: "350px" }}
                    wrapperStyle={{ height: "420px" }}
                    onEditorStateChange={editorState => {
                      setQuestionState(editorState)
                    }}
                  >
                    <textarea
                      disabled
                      value={draftToHtml(
                        convertToRaw(questionState.getCurrentContent())
                      )}
                    />
                  </Editor>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={2}>
              <Input
                type="button"
                value="Kaydet"
                className="btn btn-success mb-3"
                onClick={saveQuestion}
              />
            </Col>{" "}
            <Col xs={12} md={2}>
              <Input
                type="button"
                value="Önizle"
                className="btn btn-warning mb-3"
                onClick={() => setInfoMdlIsOpen(true)}
              />
            </Col>
          </Row>
        </div>
      </div>
      {sweetAlert ? (
        <SweetAlert
          title="Soru kaydedildi!"
          timeout={2000}
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
          }}
          showCloseButton={false}
          showConfirm={false}
          success
          onConfirm={onSuccess}
        ></SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert
          title={errorAlert.message}
          timeout={2000}
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
          }}
          showCloseButton={false}
          showConfirm={false}
          error
          onConfirm={() => {
            setErrorAlert(undefined)
          }}
        ></SweetAlert>
      ) : null}
      <Modal
        size="md"
        isOpen={mdlInfoIsOpen}
        toggle={() => setInfoMdlIsOpen(!mdlInfoIsOpen)}
      >
        <div className="modal-header">
          <h5
            className="modal-title mt-0 text-uppercase"
            id="myExtraLargeModalLabel"
          >
            Önizleme
          </h5>
          <button
            onClick={() => setInfoMdlIsOpen(false)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Row>
              <Col md={12}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: draftToHtml(
                      convertToRaw(questionState.getCurrentContent())
                    ),
                  }}
                ></div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={12} md={4}>
                <Input
                  type="button"
                  value="Kaydet"
                  className="btn btn-success mb-3"
                  onClick={saveQuestion}
                />
              </Col>{" "}
            </Row>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default AnxietyQuestionEditor
