import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Label,
  Badge,
  Modal,
  Input,
} from 'reactstrap';
import MetaTags from 'react-meta-tags';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import '../../styles/datatables.scss';
import { apiGet, apiPost } from 'helpers/api_helper';
import { useHistory } from 'react-router-dom';
import { isUrl } from 'helpers/general';

const QuestionIndex = () => {
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [mdlInfoIsOpen, setInfoMdlIsOpen] = useState(false);
  const [rowData, setRowData] = useState({
    question: '',
    answers: [],
    isTrue: false,
    tag: '',
  });

  const actionsBtnGroup = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Row>
          <Col md={12}>
            {row.status === 0 && (
              <Button
                className="btn btn-sm btn-success w-100"
                onClick={() => {
                  apiPost(`question/activate/${row._id}`).then(() =>
                    getQuestions(),
                  );
                }}
              >
                Aktifleştir
              </Button>
            )}
            {row.status === 1 && (
              <Button
                className="btn btn-sm btn-warning w-100"
                onClick={() => {
                  apiPost(`question/deactivate/${row._id}`).then(() =>
                    getQuestions(),
                  );
                }}
              >
                Pasifleştir
              </Button>
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Button
              className="btn btn-sm btn-info w-100"
              onClick={() => {
                setRowData(tableData.find(item => row._id === item._id));
                setInfoMdlIsOpen(true);
              }}
            >
              Önizle
            </Button>
          </Col>
        </Row>
      </>
    );
  };
  const questionRender = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Row>
          <Col md={12}>
            <div
              dangerouslySetInnerHTML={{
                __html: row.question,
              }}
            ></div>
          </Col>
        </Row>
      </>
    );
  };
  const statusGroup = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {row.status === 0 && (
          <Badge className="bg-warning text-lg">Pasif</Badge>
        )}
        {row.status === 1 && (
          <Badge className="bg-success text-lg">Aktif</Badge>
        )}
      </>
    );
  };
  const typeGroup = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {row.type === 'anxiety' && <p className="text-lg">Kaygı Sorusu</p>}
        {row.type === 'standart' && <p className="text-lg">Test Sorusu</p>}
        {row.type === 'summation' && <p className="text-lg">Toplam Sonuç Sorusu</p>}
      </>
    );
  };

  const getQuestions = async (branch = 0) => {
    const { data } = await apiGet(`question/get/${branch}`, true);
    setTableData(data);
  };

  useEffect(getQuestions, [0]);

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc',
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length,
    custom: true,
  };
  const columns = [
    {
      dataField: '_id',
      text: 'Id',
      sort: false,
    },
    {
      dataField: 'branch',
      text: 'Sınıf',
      sort: true,
    },
    {
      dataField: 'question',
      text: 'Soru',
      sort: true,
      formatter: questionRender,
    },
    {
      dataField: 'status',
      text: 'Durum',
      formatter: statusGroup,
      sort: true,
    },
    {
      dataField: 'type',
      text: 'Tür',
      formatter: typeGroup,
      sort: true,
    },
    {
      dataField: 'actions',
      text: 'İşlemler',
      formatter: actionsBtnGroup,
      sort: true,
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Esnek Beyin / Sorular</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Sorular" />

          <Row>
            <Col className="col-12">
              <Col className="col-3">
                <a
                  href="/questions/editor"
                  className="btn btn-success w-100 mb-3"
                >
                  Klasik Soru Oluştur
                </a>
              </Col>
              <Col className="col-3">
                <a
                  href="/questions/anxiety-editor"
                  className="btn btn-info w-100 mb-3"
                >
                  Kaygı Sorusu Oluştur
                </a>
              </Col>
              <Col className="col-3">
                <a
                  href="/questions/total-editor"
                  className="btn btn-info w-100 mb-3"
                >
                  Toplam Sonuç Sorusu Oluştur
                </a>
              </Col>
              <br className="mb-2" />
              <Card>
                <CardBody>
                  <Label>Sınıf</Label>
                  <select
                    className="form-select"
                    style={{ maxWidth: '90px' }}
                    onChange={e => getQuestions(e.target.value)}
                  >
                    <option value="0" defaultValue>
                      0
                    </option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={tableData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={tableData}
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                  <CardFooter>
                    <Label className="text-danger">
                      (* 0 her sınıfta geçerli anlamına gelir)
                    </Label>
                    <br />
                    <Label className="text-danger">
                      (* Sınıf numarası örn: 1 5 6 7 11)
                    </Label>
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        size="md"
        isOpen={mdlInfoIsOpen}
        toggle={() => setInfoMdlIsOpen(!mdlInfoIsOpen)}
      >
        <div className="modal-header">
          <h5
            className="modal-title mt-0 text-uppercase"
            id="myExtraLargeModalLabel"
          >
            Önizleme
          </h5>
          <button
            onClick={() => setInfoMdlIsOpen(false)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Row>
              <Col md={12}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: rowData.question,
                  }}
                ></div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={12}>
                {rowData.answers.map(subItem => {
                  return (
                    <p
                      className={
                        subItem.isTrue
                          ? 'text-success mb-0'
                          : 'text-muted mb-0'
                      }
                    >
                      {subItem.tag} -
                      {!isUrl(subItem.text) && (
                        <label
                          className="form-check-label"
                          htmlFor={`${subItem._id}`}
                        >
                          {subItem.text}{' '}
                        </label>
                      )}
                      {isUrl(subItem.text) && (
                        <figure className="mb-6">
                          <img
                            className="img-fluid"
                            src={subItem.text}
                            alt=""
                            style={{ width: '400px' }}
                          />
                        </figure>
                      )}
                    </p>
                  );
                })}
              </Col>
            </Row>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default QuestionIndex;
