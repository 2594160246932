import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link, useHistory, withRouter } from "react-router-dom"
import {
  Col,
  Container,
  Row,
  Table,
  Card,
  CardBody,
  Label,
  Input,
  Modal,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { apiGet, apiPost } from "../../helpers/api_helper"
import SweetAlert from "react-bootstrap-sweetalert"
import { isUrl } from "helpers/general"

const AnxietyEditor = () => {
  const history = useHistory()
  const [mdlIsOpen, setMdlIsOpen] = useState(false)
  const [questions, setQuestions] = useState([])
  const [errorAlert, setErrorAlert] = useState(undefined)
  const [sweetAlert, setSweetAlert] = useState(false)
  const [selectedQuestions, setSelectedQuestions] = useState([])

  const [branch, setBranch] = useState(0)
  const [header, setHeader] = useState("")
  const [description, setDescription] = useState("")
  const [type, setType] = useState(3)
  const [repeatable, setRepeatable] = useState(true)
  const [quizResults, setQuizResults] = useState([])

  const getQuestions = async (branch = 0) => {
    const { data } = await apiGet(`question/get-anxiety/${branch}`, true)
    const idList = selectedQuestions.map(item => item._id)
    const filteredList = data.filter(item => !idList.includes(item._id))
    setQuestions(filteredList)
  }

  const addQuestion = id => {
    const question = questions.find(item => item._id === id)
    setSelectedQuestions([...selectedQuestions, question])

    setQuestions([...questions.filter(item => item._id !== id)])
  }
  const removeQuestion = id => {
    const selected = selectedQuestions.find(item => item._id === id)
    setQuestions([...questions, selected])

    setSelectedQuestions([...selectedQuestions.filter(item => item._id !== id)])
  }

  const onSuccess = () => {
    setSweetAlert(false)
    history.push("/quizes")
  }
  const save = () => {
    apiPost(
      "quiz/save",
      {
        header,
        description,
        type,
        questions: selectedQuestions,
        branch,
        repeatable,
        results: quizResults.length > 0 ? quizResults : null
      },
      true
    )
      .then(() => {
        setMdlIsOpen(false)
        setSweetAlert(true)
      })
      .catch(e => setErrorAlert(e.response))
  }

  useEffect(() => {
    getQuestions(branch)
  }, [0])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Esnek Beyin - Deneme Sınavı</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Projects" breadcrumbItem="Deneme Sınavı" />

          <Row>
            <Col lg="12">
              <Card className="overflow-hidden p-4">
                <CardBody className="pt-0">
                  <div className="bg-soft">
                    <Row className="mb-3">
                      <Col xs={12} md={3}>
                        <Label>Sınıf</Label>
                        <br />
                        <i>Uygulanacak sınıfı seçin!</i>
                        <select
                          className="form-select"
                          onChange={e => {
                            getQuestions(e.target.value)
                            setBranch(parseInt(e.target.value))
                          }}
                        >
                          <option value="0" defaultValue>
                            0
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} md={3}>
                        <Label>Başlık</Label>
                        <Input
                          name="header"
                          className="form-control"
                          placeholder="Sınav için bir başlık giriniz"
                          type="text"
                          required
                          value={header}
                          onChange={e => setHeader(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} md={3}>
                        <Label>Açıklama</Label>
                        <Input
                          type="textarea"
                          id="textarea"
                          maxLength="225"
                          rows="3"
                          placeholder="Bir açıklama yazınız"
                          value={description}
                          onChange={e => setDescription(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} md={3}>
                        <Label>Sınav türü</Label>
                        <br />
                        <i>Uygulanacak sınav türünü seçin!</i>
                        <select
                          className="form-select"
                          onChange={e => setType(parseInt(e.target.value))}
                        >
                          <option value="3" defaultValue>
                            Kaygı Testi
                          </option>
                        </select>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} md={3}>
                        <Label>Tekrar edilebilirlik</Label>
                        <div className="form-check form-switch form-switch-lg mb-3">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizelg"
                            defaultChecked
                            checked={repeatable}
                            onChange={() => setRepeatable(!repeatable)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} md={3}>
                        <Label>Özelleştirilmiş Sonuç</Label>
                        <br></br>
                        <button
                          className="btn btn-md btn-success"
                          onClick={() => setQuizResults(quizResults => [...quizResults, { min: 0, max: 100, result: '' }])
                          }
                        >
                          Ekle
                          <i className="fa fa-fw fa-plus" />
                        </button>
                      </Col>
                    </Row>
                    <hr></hr>

                    {quizResults.map((item, index) => {
                      return <>
                        <Row className="mb-3">
                          <Col xs={12} md={3}>
                            <Label>Alt Sınır</Label>
                            <Input
                              name="header"
                              className="form-control"
                              placeholder="Alt sınırı belirleyiniz"
                              type="number"
                              defaultValue={0}
                              required
                              value={item.min}
                              onChange={e => {
                                setQuizResults([...quizResults.map((res, i) =>
                                  i === index ? { ...res, min: parseInt(e.target.value) } : res
                                ),
                                ])
                              }}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col xs={12} md={3}>
                            <Label>Üst Sınır</Label>
                            <Input
                              name="header"
                              className="form-control"
                              placeholder="Alt sınırı belirleyiniz"
                              type="number"
                              defaultValue={100}
                              required
                              value={item.max}
                              onChange={e => {
                                setQuizResults([...quizResults.map((res, i) =>
                                  i === index ? { ...res, max: parseInt(e.target.value) } : res
                                ),
                                ])
                              }}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col xs={12} md={3}>
                            <Label>Açıklama</Label>
                            <Input
                              type="textarea"
                              id="textarea"
                              maxLength="225"
                              rows="3"
                              placeholder={`${item.min} ile ${item.max} arasında göstermek istediğiniz çıktıyı ekleyiniz`}
                              value={item.result}
                              onChange={e => {
                                setQuizResults([...quizResults.map((res, i) =>
                                  i === index ? { ...res, result: e.target.value } : res
                                ),
                                ])
                              }}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col xs={12} md={3}>
                            <br></br>
                            <button
                              className="btn btn-md btn-danger"
                              onClick={() => setQuizResults(quizResults => [...quizResults.filter((res, i) => i !== index)])
                              }
                            >
                              Çıkar
                              <i className="fa fa-fw fa-minus" />
                            </button>
                          </Col>
                        </Row>
                        <hr></hr>
                      </>
                    })}
                  </div>
                  <Row>
                    <Col lg={9}></Col>
                    <Col lg={3}>
                      <button
                        className="btn btn-md btn-success w-100"
                        onClick={() => setMdlIsOpen(true)}
                      >
                        Oluştur
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <div className="">
                <h4>Sorular</h4>
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Soru ve Cevapları</th>
                        <th scope="col">Sınıf</th>
                      </tr>
                    </thead>
                    <tbody>
                      {questions.map(item => {
                        return (
                          <tr key={item._id}>
                            <td>
                              <Label
                                className="text-truncate font-size-12"
                                dangerouslySetInnerHTML={{
                                  __html: item.question,
                                }}
                              ></Label>
                              {item.answers.map(subItem => {
                                return (
                                  <p
                                    className={
                                      subItem.isTrue
                                        ? "text-success mb-0"
                                        : "text-muted mb-0"
                                    }
                                  >
                                    {subItem.tag} -
                                    {!isUrl(subItem.text) && (
                                      <label
                                        className="form-check-label"
                                        htmlFor={`${subItem._id}`}
                                      >
                                        {subItem.text}{' '}
                                      </label>
                                    )}
                                    {isUrl(subItem.text) && (
                                      <figure className="mb-6">
                                        <img
                                          className="img-fluid"
                                          src={subItem.text}
                                          alt=""
                                          style={{ width: '400px' }}
                                        />
                                      </figure>
                                    )}
                                  </p>
                                )
                              })}
                              <button
                                className="btn btn-xs btn-success"
                                onClick={() => addQuestion(item._id)}
                              >
                                Ekle
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="">
                <h4>Deneme Testi</h4>
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Soru ve Cevapları</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedQuestions.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Label
                                className="text-truncate font-size-12"
                                dangerouslySetInnerHTML={{
                                  __html: item.question,
                                }}
                              ></Label>
                              {item.answers.map(subItem => {
                                return (
                                  <p
                                    className={
                                      subItem.isTrue
                                        ? "text-success mb-0"
                                        : "text-muted mb-0"
                                    }
                                  >
                                    {subItem.tag} - {subItem.text}
                                  </p>
                                )
                              })}
                              <button
                                className="btn btn-xs btn-secondary"
                                onClick={() => removeQuestion(item._id)}
                              >
                                Çıkart
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        size="md"
        isOpen={mdlIsOpen}
        toggle={() => setMdlIsOpen(!mdlIsOpen)}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            Önizleme
          </h5>
          <button
            onClick={() => setMdlIsOpen(false)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {selectedQuestions.map((item, index) => {
            return (
              <div className={"mt-3"} key={index}>
                <Label
                  className="text-truncate font-size-12"
                  dangerouslySetInnerHTML={{
                    __html: item.question,
                  }}
                ></Label>
                <br />
                {item.answers.map(subItem => {
                  return (
                    <p
                      className={
                        subItem.isTrue ? "text-success mb-0" : "text-muted mb-0"
                      }
                    >
                      {subItem.tag} - {subItem.text}
                    </p>
                  )
                })}
                <hr />
              </div>
            )
          })}
          <Row>
            <Col lg={3}></Col>
            <Col lg={3}>
              <button
                className="btn btn-md btn-danger w-100"
                onClick={() => setMdlIsOpen(false)}
              >
                Geri
              </button>
            </Col>
            <Col lg={3}>
              <button
                className="btn btn-md btn-success w-100"
                onClick={save}
                // disabled={selectedQuestions.length < 10}
                title={
                  selectedQuestions.length < 10
                    ? "En az 10 soru ekleyin"
                    : "Sınav oluşturmak için tıklayınız"
                }
              >
                Kaydet
              </button>
            </Col>
          </Row>
        </div>
      </Modal>
      {errorAlert ? (
        <SweetAlert
          title={errorAlert.message}
          timeout={2000}
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
          }}
          showCloseButton={false}
          showConfirm={false}
          error
          onConfirm={() => {
            setErrorAlert(undefined)
          }}
        ></SweetAlert>
      ) : null}

      {sweetAlert ? (
        <SweetAlert
          title="Sınav oluşturuldu!"
          timeout={2000}
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
          }}
          showCloseButton={false}
          showConfirm={false}
          success
          onConfirm={onSuccess}
        ></SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

export default withRouter(AnxietyEditor)
