
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Modal, Label, Input, Button } from "reactstrap"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../styles/datatables.scss"
import { apiGet, apiPost } from "helpers/api_helper"

const UserQuestionsIndex = () => {
  const [tableData, setTableData] = useState([])
  const [rowData, setRowData] = useState({})
  const [mdlIsOpen, setMdlIsOpen] = useState(false)
  const [answer, setAnswer] = useState('')


  const actionsBtnGroup = (cell, row, rowIndex, formatExtraData) => {
    if (row.status !== 1) {
      return (
        <>
          <Row>
            <Col md={12}>
              {row.answer ? (<><i>Cevaplandı</i></>) : (<><i>Cevaplanmadı</i></>)}
            </Col>
          </Row>
        </>
      )
    }
  }



  const columns = [
    {
      dataField: "username",
      text: "Kullanıcı Adı",
      sort: false,
    },
    {
      dataField: "fileName",
      text: "Ders adı",
      sort: true,
    },
    {
      dataField: "question",
      text: "Soru",
      sort: true,
    },
    {
      dataField: "answer",
      text: "Durum",
      sort: true,
      formatter: actionsBtnGroup
    },
  ]
  const getQuestions = async () => {
    const { data } = await apiGet("inventory/question", true)
    setTableData(data)
  }



  const sendAnswer = async () => {
    await apiPost(`inventory/question/${rowData._id}`, { answer }, true).then(() => {
      setMdlIsOpen(false);
      getQuestions()
    })
  }


  useEffect(getQuestions, [0])

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: tableData.length },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const onRowClick = (e, row, rowIndex) => {
    setRowData(tableData.find(item => row._id === item._id))
    setMdlIsOpen(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Esnek Beyin / Blog</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs breadcrumbItem="Ders Soruları" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={tableData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={tableData}
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    rowEvents={{ onClick: onRowClick }}
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        size="md"
        isOpen={mdlIsOpen}
        toggle={() => setMdlIsOpen(!mdlIsOpen)}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            Soru
          </h5>
          <button
            onClick={() => setMdlIsOpen(false)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>{rowData.question}</p>
          <hr></hr>
          <Row className="mb-3">
            <Col xs={12} md={12}>
              <Label>Cevap</Label>
              <Input
                type="textarea"
                id="textarea"
                maxLength="225"
                rows="3"
                placeholder="Bir cevap yazınız"
                value={answer}
                onChange={e => setAnswer(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <center>
                <Button onClick={sendAnswer}>Gönder</Button>
              </center>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default UserQuestionsIndex
