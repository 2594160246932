import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Badge,
  Modal,
} from 'reactstrap';
import MetaTags from 'react-meta-tags';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import '../../styles/datatables.scss';
import { apiGet } from 'helpers/api_helper';
import { useHistory } from 'react-router-dom';
import { isUrl } from 'helpers/general';

const BlogIndex = () => {
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [rowData, setRowData] = useState({ questions: [] });
  const [mdlIsOpen, setMdlIsOpen] = useState(false);

  const actionsBtnGroup = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Row className="mb-2">
          <Button
            onClick={() => {
              setRowData(row);
              setMdlIsOpen(true);
            }}
            className="btn btn-sm btn-info"
          >
            Önizle
          </Button>
        </Row>
      </>
    );
  };
  const statusGroup = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {row.status === 0 && (
          <Badge className="bg-warning text-lg">Pasif</Badge>
        )}
        {row.status === 1 && (
          <Badge className="bg-success text-lg">Aktif</Badge>
        )}
      </>
    );
  };
  const typeGroup = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {row.type === 0 && (
          <Badge className="bg-secondary text-lg">Değerlendirme</Badge>
        )}
        {row.type === 1 && (
          <Badge className="bg-secondary text-lg">Sınav</Badge>
        )}
        {row.type === 2 && <Badge className="bg-secondary text-lg">Ödev</Badge>}
      </>
    );
  };
  const getQuizes = async () => {
    const { data } = await apiGet('quiz/admin-get-all', true);
    setTableData(data);
  };

  useEffect(getQuizes, [0]);

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc',
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length,
    custom: true,
  };
  const columns = [
    {
      dataField: '_id',
      text: 'Id',
      sort: false,
    },
    {
      dataField: 'header',
      text: 'Sınav Adı',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Durum',
      formatter: statusGroup,
      sort: true,
    },
    {
      dataField: 'branch',
      text: 'Sınıf',
      sort: true,
    },
    {
      dataField: 'type',
      text: 'Tür',
      sort: true,
      formatter: typeGroup,
    },
    {
      dataField: 'actions',
      text: 'İşlemler',
      formatter: actionsBtnGroup,
      sort: true,
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Esnek Beyin / Deneme Testleri</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Deneme Testleri" />

          <Row>
            <Col className="col-12">
              <Col className="col-3">
                <a href="/quizes/editor" className="btn btn-success w-100 mb-3">
                  Klasik Test Oluştur
                </a>
              </Col>
              <Col className="col-3">
                <a
                  href="/quizes/anxiety-editor"
                  className="btn btn-info w-100 mb-3"
                >
                  Kaygı Testi Oluştur
                </a>
              </Col>
              <Col className="col-3">
                <a
                  href="/quizes/total-result-editor"
                  className="btn btn-info w-100 mb-3"
                >
                  Toplam Sonuç Testi Oluştur
                </a>
              </Col>
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={tableData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={tableData}
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        size="lg"
        isOpen={mdlIsOpen}
        toggle={() => setMdlIsOpen(!mdlIsOpen)}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            Önizleme - {rowData.header}
          </h5>
          <button
            onClick={() => setMdlIsOpen(false)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>{rowData.description}</p>
          <hr />
          {rowData.questions.map((item, index) => {
            return (
              <div className={'mt-3'} key={index}>
                <Label
                  className="text-truncate font-size-12 text-wrap"
                  dangerouslySetInnerHTML={{
                    __html: item.question,
                  }}
                ></Label>
                <br />
                {item.answers.map(subItem => {
                  return (
                    <p
                      className={
                        subItem.isTrue
                          ? 'text-success mb-0'
                          : 'text-muted mb-0'
                      }
                    >
                      {subItem.tag} -
                      {!isUrl(subItem.text) && (
                        <label
                          className="form-check-label"
                          htmlFor={`${subItem._id}`}
                        >
                          {subItem.text}{' '}
                        </label>
                      )}
                      {isUrl(subItem.text) && (
                        <figure className="mb-6">
                          <img
                            className="img-fluid"
                            src={subItem.text}
                            alt=""
                            style={{ width: '400px' }}
                          />
                        </figure>
                      )}
                    </p>
                  );
                })}
                <hr />
              </div>
            );
          })}
          <Row>
            <Col lg={9}></Col>
            <Col lg={3}>
              <button
                className="btn btn-md btn-danger w-100"
                onClick={() => setMdlIsOpen(false)}
              >
                Geri
              </button>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default BlogIndex;
