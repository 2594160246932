import axios from 'axios';
import { token_cookie_key } from 'constants/general';
import { getCookie } from './cookie';

const axiosApi = (includeToken = true) => {
  const headers = {};

  if (includeToken) {
    headers['x-auth-token-admin'] = getCookie(token_cookie_key);
  }

  return axios.create({
    baseURL: 'https://api.esnekbeyin.com/',
    // baseURL: 'http://localhost:8080/',
    headers,
  });
};

export async function apiGet(url, includeToken) {
  return axiosApi(includeToken).get(url);
}

export async function apiPost(url, data, includeToken) {
  return axiosApi(includeToken).post(url, data);
}

export async function apiPut(url, data, includeToken) {
  return axiosApi(includeToken).put(url, data);
}

export async function apiDelete(url, includeToken) {
  return axiosApi(includeToken).delete(url);
}
