import React, { useState, useRef, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Col,
  Container,
  Row,
  Table,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Modal,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { apiGet, apiPut } from "../../helpers/api_helper"
import SweetAlert from "react-bootstrap-sweetalert"
import { useHistory, useParams } from "react-router-dom"

const FileUpload = () => {
  const history = useHistory()
  const params = useParams()
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [afterSale, setAfterSale] = useState("")
  const [price, setPrice] = useState(0)
  const [quantity, setQuantity] = useState(0)
  const [fixedId, setFixedId] = useState(0)

  function saveContent() {
    apiPut(`sessionpack/${fixedId}`, {
      name,
      description,
      quantity,
      price,
      afterSale
    }, true)
      .then(() => history.push('/sessions'))
  }

  const getSessionBySlug = () => {
    apiGet(`sessionpack/${params.id}`).then(({ data }) => {
      setName(data.name)
      setDescription(data.description)
      setQuantity(data.quantity)
      setPrice(data.price)
      setAfterSale(data.afterSale)
      setFixedId(data.fixedId)
    })
  }

  useEffect(() => {
    getSessionBySlug()
  }, [0]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Seans Güncelle</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Seanslar" breadcrumbItem="Güncelleme" />
          <Row>
            <Col lg={12}>
              <Card className="overflow-hidden p-4">
                <div className="bg-soft">
                  <Row className="mb-3">
                    <Col xs={12} md={6}>
                      <Label>Seans Adı</Label>
                      <Input
                        name="header"
                        className="form-control"
                        placeholder="Seans Adı!"
                        type="text"
                        required
                        value={name}
                        onChange={e => setName(e.target.value)}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} md={6}>
                      <Label>Seans Açıklaması</Label>
                      <Input
                        name="header"
                        className="form-control"
                        placeholder="Seans Açıklaması"
                        required
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        type="textarea"
                        id="textarea"
                        maxLength="225"
                        rows="6"
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} md={6}>
                      <Label>Satış Sonrası Detay</Label>
                      <Input
                        name="header"
                        className="form-control"
                        placeholder="Satış Sonrası Detay"
                        required
                        value={afterSale}
                        onChange={e => setAfterSale(e.target.value)}
                        type="textarea"
                        id="textarea"
                        maxLength="225"
                        rows="6"
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} md={6}>
                      <Label>Seans Ücreti</Label>
                      <Input
                        name="header"
                        className="form-control"
                        placeholder="Seans Ücreti!"
                        type="number"
                        required
                        value={price}
                        onChange={e => setPrice(parseInt(e.target.value))}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} md={6}>
                      <Label>Seans Adedi</Label>
                      <Input
                        name="header"
                        className="form-control"
                        placeholder="Seans Adedi!"
                        type="number"
                        required
                        value={quantity}
                        onChange={e => setQuantity(parseInt(e.target.value))}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mb-3">
                    <Col xs={12} md={3}>
                      <Button
                        className="btn btn-md btn-success w-100"
                        onClick={saveContent}
                      >
                        Kaydet
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default FileUpload
