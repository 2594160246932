import React, { useState, useRef, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  Label,
  Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { apiGet, apiPost } from "../../helpers/api_helper"
import SweetAlert from "react-bootstrap-sweetalert"
import { useHistory, useParams } from "react-router-dom"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

const PackagesEditor = () => {
  const history = useHistory()
  const params = useParams()
  const thumbnailRef = useRef(null)

  // -----------------States-----------------
  const [packageName, setPackageName] = useState("")
  const [packageDescription, setPackageDescription] = useState("")
  const [packageShortDescription, setPackageShortDescription] = useState("")
  const [packageGoal, setGoal] = useState("")
  const [packageResult, setResult] = useState("")
  const [branch, setBranch] = useState(0)
  const [price, setPrice] = useState(0)
  const [thumbnail, setThumbnail] = useState(null)
  const [thumbnailURL, setThumbnailURL] = useState(null)
  const [thumbnailPreview, setThumbnailPreview] = useState(null)
  const [files, setFiles] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [tableData, setTableData] = useState([])
  const [tableDataSelected, setTableDataSelected] = useState([])

  // ------------------Utilities----------------

  const [sweetalert, setSweetAlert] = useState(false)
  const [fileUploadAlert, setFileUploadAlert] = useState(false)
  const [fileUploadAlertError, setFileUploadAlertError] = useState(false)

  function savePackage() {
    const data = {
      name: packageName,
      description: packageDescription,
      shortDescription: packageShortDescription,
      goal: packageGoal,
      result: packageResult,
      branch,
      price,
      thumbnail: thumbnailURL,
    }

    if (!params.id) {
      data.files = tableDataSelected.map(item => item._id)
      apiPost("package/", data, true).then(() => setFileUploadAlert(true))
    } else {
      apiPost(`package/${params.id}`, data).then(() => setFileUploadAlert(true))
    }
  }

  const typeRender = (cell, row, rowIndex, formatExtraData) => {
    if (row.isPaid) {
      return <>Ücretli</>
    }
    return <>Ücretsiz</>
  }

  const addFile = async id => {
    const selectedFile = tableData.find(item => item._id === id)

    setTableDataSelected(arr => [...arr, { ...selectedFile, isSelected: true }])
    setTableData(arr => [...arr.filter(item => item._id !== id)])
  }
  const removeFile = async id => {
    const selectedFile = tableDataSelected.find(item => item._id === id)

    setTableDataSelected(arr => [...arr.filter(item => item._id !== id)])
    setTableData(arr => [...arr, { ...selectedFile, isSelected: false }])
  }

  const actionsBtnGroup = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Row>
          <Col md={12}>
            {row.isSelected && (
              <Button
                onClick={() => removeFile(row._id)}
                className="btn btn-sm btn-danger w-100"
              >
                Çıkar
              </Button>
            )}
            {!row.isSelected && (
              <Button
                onClick={() => addFile(row._id)}
                className="btn btn-sm btn-success w-100"
              >
                Ekle
              </Button>
            )}
          </Col>
        </Row>
      </>
    )
  }
  const columns = [
    {
      dataField: "name",
      text: "Dosya Adı",
      sort: true,
    },
    {
      dataField: "fileType",
      text: "Dosya Uzantısı",
      sort: true,
    },
    {
      dataField: "branch",
      text: "Sınıf",
      sort: true,
    },
    {
      dataField: "isPaid",
      text: "Tür",
      sort: true,
      formatter: typeRender,
    },
    {
      dataField: "actions",
      text: "İşlemler",
      sort: true,
      formatter: actionsBtnGroup,
    },
  ]
  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]
  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length,
    custom: true,
  }

  const getFiles = () => {
    return apiGet("file/", true).then(({ data }) => setTableData(data))
  }
  const getPackage = () => {
    return apiGet(`package/${params.id}`)
      .then(({ data }) => {
        setPackageName(data.name)
        setPackageDescription(data.description)
        setPackageShortDescription(data.shortDescription)
        setGoal(data.goal)
        setResult(data.result)
        setBranch(data.branch)
        setThumbnailURL(data.thumbnail)
        setPrice(data.price)
      })
      .catch(() => {
        alert("Bir hata oluştur")
      })
  }

  useEffect(() => {
    if (thumbnail) {
      const formData = new FormData()
      formData.append("content", thumbnail)
      return apiPost("file/upload-pub-content", formData, true).then(
        ({ data }) => {
          setThumbnailURL(data)
          setThumbnailPreview(URL.createObjectURL(thumbnail))
          setSweetAlert(true)
        }
      )
    }
    return setThumbnailPreview(null)
  }, thumbnail)

  useEffect(() => {
    if (params.id) {
      getPackage(params.id)
    } else {
      getFiles()
    }
  }, [0])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Eğitim Paketi Oluştur</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Eğitim Paketleri" breadcrumbItem="Oluştur" />
          <Row>
            <Col lg={12}>
              <Card className="overflow-hidden p-4">
                <div className="bg-soft">
                  <Row className="mb-3">
                    <Col xs={12} md={6} lg={4}>
                      <Label>Eğitim Paketinin Adı</Label>
                      <Input
                        name="header"
                        className="form-control"
                        placeholder="Eğitim Paketi için bir ad belirleyin!"
                        type="text"
                        required
                        value={packageName}
                        onChange={e => setPackageName(e.target.value)}
                      />
                    </Col>
                  </Row>{" "}
                  <Row className="mb-3">
                    <Col xs={12} md={6} lg={4}>
                      <Label>Eğitim Paketinin Fiyatı</Label>
                      <Input
                        name="header"
                        className="form-control"
                        placeholder="Eğitim Paketi için bir ad belirleyin!"
                        type="number"
                        required
                        value={price}
                        onChange={e => setPrice(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} md={6} lg={4}>
                      <Label>Açıklama</Label>
                      <Input
                        type="textarea"
                        id="textarea"
                        onChange={e => setPackageDescription(e.target.value)}
                        maxLength="225"
                        rows="3"
                        placeholder="Eğitim Paketi için detaylı açıklama yazınız!"
                        value={packageDescription}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} md={6} lg={4}>
                      <Label>Kısa Açıklama</Label>
                      <Input
                        type="textarea"
                        id="textarea"
                        onChange={e =>
                          setPackageShortDescription(e.target.value)
                        }
                        maxLength="225"
                        rows="3"
                        placeholder="Eğitim Paketi için özet açıklama giriniz!"
                        value={packageShortDescription}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} md={6} lg={4}>
                      <Label>Hedefler</Label>
                      <Input
                        type="textarea"
                        id="textarea"
                        onChange={e => setGoal(e.target.value)}
                        maxLength="225"
                        rows="3"
                        placeholder="Eğitim Paketinin amacını detaylı bir şekilde giriniz!"
                        value={packageGoal}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} md={6} lg={4}>
                      <Label>Kazanımlar</Label>
                      <Input
                        type="textarea"
                        id="textarea"
                        onChange={e => setResult(e.target.value)}
                        maxLength="225"
                        rows="3"
                        placeholder="Eğitim Paketi tamamlandığında elde edilecek kazanımları giriniz!"
                        value={packageResult}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} md={6} lg={4}>
                      <Label>Sınıf</Label>
                      <br />
                      <i>
                        Eğitim Paketinin geçerli olduğu sınıf numarasını
                        seçiniz!
                      </i>
                      <select
                        className="form-select"
                        onChange={e => {
                          setBranch(parseInt(e.target.value))
                        }}
                      >
                        <option value="0" defaultValue>
                          0
                        </option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} md={6} lg={4}>
                      <Button
                        className="btn btn-md btn-info w-100"
                        onClick={() => {
                          thumbnailRef.current.click()
                        }}
                      >
                        Kapak Fotoğrafı Yükle
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    {thumbnailPreview && (
                      <Col xs={12} md={3}>
                        <center>
                          <img
                            style={{ border: ".5px solid gray" }}
                            src={thumbnailPreview}
                            alt="Önizleme"
                            className="thumbnail mb-3"
                            width={200}
                            height={200}
                          />
                          <br />
                          <Button
                            className="btn btn-md btn-danger w-50"
                            onClick={() => {
                              setThumbnail(null)
                              setThumbnailPreview(null)
                              setThumbnailURL(null)
                            }}
                          >
                            Sil
                          </Button>
                        </center>
                      </Col>
                    )}
                  </Row>
                  <hr />
                  <Row hidden={!params.id}>
                    <center>
                      <i>İçerik güncellenemez!</i>
                    </center>
                  </Row>
                  <Row hidden={params.id}>
                    <Col md={6}>
                      <Card>
                        <CardBody>
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            keyField="id"
                            columns={columns}
                            data={tableData}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={columns}
                                data={tableData}
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={"id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card>
                        <CardBody>
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            keyField="id"
                            columns={columns}
                            data={tableDataSelected}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={columns}
                                data={tableDataSelected}
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={"id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mb-3">
                    <Col xs={12} md={3}>
                      <Button
                        className="btn btn-md btn-success w-100"
                        onClick={savePackage}
                      >
                        Kaydet
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
        <input
          hidden={true}
          type="file"
          multiple={false}
          ref={thumbnailRef}
          accept={[".png", ".jpg", ".jpeg"]}
          onChange={e => {
            setThumbnail(e.target.files[0])
            setThumbnailPreview(e.target.result)
          }}
        />
        {sweetalert ? (
          <SweetAlert
            title="Kapak fotoğrafı eklendi"
            timeout={2000}
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
            }}
            showCloseButton={false}
            showConfirm={false}
            success
            onConfirm={() => setSweetAlert(false)}
          ></SweetAlert>
        ) : null}{" "}
        {fileUploadAlert ? (
          <SweetAlert
            title="Eğitim Paketi Kaydedildi"
            timeout={2000}
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
            }}
            showCloseButton={false}
            showConfirm={false}
            success
            onConfirm={() => history.push("/packages")}
          ></SweetAlert>
        ) : null}{" "}
        {fileUploadAlertError ? (
          <SweetAlert
            title="İçerik yüklenirken bir hata oluştur!"
            timeout={2000}
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
            }}
            showCloseButton={false}
            showConfirm={false}
            error
            onConfirm={() => setFileUploadAlertError(false)}
          ></SweetAlert>
        ) : null}
      </div>
    </React.Fragment>
  )
}
export default PackagesEditor
